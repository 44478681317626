<script setup lang="ts">
const props = defineProps({
  preOpen: {
    type: String,
    default: "",
  },
  // pass: {
  //   type: [Boolean, String],
  //   default: false,
  // },
});

const inialized = ref(false);
const isOpen = ref<string | false>(false);

const open = (modal = "") => {
  isOpen.value = modal;
  inialized.value = true;
};

const close = () => {
  isOpen.value = false;
};

if (props.preOpen !== "") {
  open(props.preOpen);
  const router = useRouter();

  const route = useRoute();

  if (route.query?.preOpen) {
    router.replace({ query: undefined });
  }
}
</script>

<template>
  <slot :open="open" :close="close" :is-open="isOpen" :was-open="inialized" />
</template>
